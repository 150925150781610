import { useEffect } from "react";
import Linkify from 'react-linkify';
export default function LinkCard (props) {
    const isUrl = word => {
        const urlPattern = /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})?$/gm;
        return word.match(urlPattern)
    }
    const addMarkup = word => {
        return isUrl(word) ? 
            `<a href="${word}" target="_blank">${word}</a>`:  
            word
    }
    const words = props.content.split(' ')
    const formatedWords = words.map((w, i) => addMarkup(w))
    let html = formatedWords.join(' ')
    
    if (props.type === 'user') {
        html = `<div class="rcw-message rcw-message-client"><div class="rcw-client"><div class="rcw-message-text">
                    ${html}
    </div></div></div>`
    } else{
        html =  `<div class="rcw-message ">
                    <div><div class="ac-container ac-adaptiveCard" tabindex="0" style="display: grid; flex-direction: column; justify-content: flex-start; box-sizing: border-box; flex: 0 0 auto; padding: 16px; margin: 0px; background-color: rgb(24, 119, 112);">
                    <div class="ac-textBlock" style="overflow: hidden; font-family: &quot;Source Sans Pro&quot;; font-size: 16px; color: rgb(244, 247, 249); font-weight: 400; text-align: start; line-height: 21.28px; white-space: initial; text-overflow: ellipsis; box-sizing: border-box; flex: 0 0 auto;">
                    ${html}
                    </div>
                    </div></div></div>`
    }
  useEffect(() => {
    console.log('props.content', props.content)
  }, [props.content]);

//   return <span dangerouslySetInnerHTML={{__html: html}} />;
  return <Linkify> <p dangerouslySetInnerHTML={{__html: html}} /></Linkify>
};
